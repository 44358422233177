<template>
<router-view></router-view>
</template>

<script>
export default {
  name: "authLayout"
}
</script>

<style scoped>

</style>