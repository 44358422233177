<template>
<div class="switch-component-wrapper" @click="toggle">
  <div v-if="value" :class="['switch-wrapper',color]">
    <div class="circle"></div>
  </div>
  <div v-else class="switch-wrapper off">
    <div class="circle"></div>
  </div>
</div>
</template>

<script>
export default {
  props: {value: Boolean,color:{type: String, default: 'success'}},
  methods:{
    toggle(){
      this.$emit('input', !this.value);
      if (!value){
        this.color = 'off'
      }
    }
  }
}
</script>

<style scoped>
.switch-component-wrapper{
  display: flex;
}
.switch-wrapper{
  width: 44px;
  min-height: 22px;
  display: flex;
  cursor: pointer;
  border-radius: 22px;
  align-items: center;
  padding: 2px;
  transition: all 0.5s;
}
.success{
  background: green;
  justify-content: flex-end;
}
.danger{
  background: red;
  justify-content: flex-end;
}
.warning{
  background: yellow;
  justify-content: flex-end;
}
.off{
  background: grey;
  justify-content: flex-start;
}
.circle{
  background: white;
  width: 18px;
  height: 18px;
  border-radius: 18px;
}
</style>