<template>
  <div class="hold-transition login-page">
    <div class="login-box">
      <!-- /.login-logo -->
      <div class="card card-outline card-primary">
        <div class="card-header text-center">
          <router-link to="/" class="h1"><b>{{this.$logotext}}</b></router-link>
        </div>
        <div class="card-body">
          <p class="login-box-msg">Sign in to your account</p>

          <form @submit.prevent="login">

            <small class="text-danger" v-if="errors.username">{{ errors.username[0] }}</small>
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Username" v-model="form.username">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>

            <small class="text-danger" v-if="errors.password">{{ errors.password[0] }}</small>
            <div class="input-group mb-3">
              <input type="password" class="form-control" placeholder="Password" v-model="form.password">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div class="social-auth-links text-center">
              <span class="fas fa-sort-up"></span>
              <button type="submit" class="btn btn-block btn-warning" :disabled="loading">Sign In</button>
            </div>
          </form>

        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <!-- /.login-box -->
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  data(){
    return {
      form:{
        username: null,
        password: null
      },
      errors: {},
      loading: false,
    }
  },

  methods: {
    ...mapActions({
      signIn: 'signIn'
    }),
    login(){
      this.loading = true;
      this.errors = {};
      this.signIn(this.form)
          .then(() =>{
            this.showSuccess();
            this.$router.replace({name: 'dashboard'})
          })
          .catch( (error) =>{
            this.errors = error.response.data.errors
            this.showError();
          })
      this.loading = false;
    },
    showSuccess(){
      this.$swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        icon: 'success',
        title: 'Welcome Back !!'
      })
    },
    showError(){
      this.$swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        icon: 'error',
        title: 'Invalid Username Or Password'
      })
    },
  }
}
</script>

<style scoped>
.login-page {
  width: 100%;
  min-height: 100vh;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: -o-linear-gradient(-135deg, #c850c0, #4158d0);
  background: -moz-linear-gradient(-135deg, #c850c0, #4158d0);
  background: linear-gradient(
      -135deg, #c850c0, #4158d0);
}
.card{
  background-image: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
}

</style>