<template>
    <div>
        <!-- Content Header (Page header) -->
        <!-- Main content -->
        <section class="content" style="margin-top: 10px">
            <!-- Default box -->
            <div class="card">
                <div class="card-header" style="padding-top: 15px">
                    <h3 class="card-title text-white">{{this.$route.params.name}}'s Bookings</h3>
                    <div class="card-tools">
                        <button class="btn btn-secondary btn-sm" @click="getBookings"><i class="fas fa-sync-alt"></i>
                            Refresh
                        </button>
                    </div>
                </div>
                <div class="card-body vld-parent bg-gray-light">
                  <loading :active.sync="isLoading"
                           :can-cancel="false"
                           :is-full-page="fullPage"
                           :z-index="zIndex"
                           loader="bars">
                  </loading>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead class="bg-teal">
                            <tr>
                                <th>
                                    Title
                                </th>
                                <th>
                                    Price
                                </th>
                                <th>
                                    Expires In
                                </th>
                                <th style="text-align: center">
                                    Action
                                </th>
                            </tr>
                            </thead>
                          <tbody v-if="parseInt(bookings.total) === 0">
                          <td colspan="4">
                            <div class="card-body bg-gray-light text-center text-xl">No Data To Show !!</div>
                          </td>
                          </tbody>
                            <tbody v-else>
                            <tr v-if="parseInt(bookings.total) > 0" v-for="booking in bookings.data" :key="booking.task.code">
                                <td>
                                {{ booking.task.title }}
                                </td>
                                <td>
                                  {{currency}} {{ booking.task.price }}
                                </td>
                                <td>
                                    {{ booking.expire_in | fromNow }}
                                </td>
                                <td class="project-actions text-center">
                                  <router-link :to="{name: 'offer-details', params:{code:booking.task.code}}" class="btn btn-secondary">Offer Details</router-link>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-if="parseInt(bookings.total) < 1" class="d-block justify-content-center text-center">
                            <label class="text-center"> Didn't find any Bookings to show </label> <br>
                        </div>
                    </div>
                </div>
                <!-- /.card-body -->
                <div class="card-footer clearfix">
                    <pagination class="pagination pagination-sm m-0 float-right" :data="bookings"
                                @pagination-change-page="getBookings"></pagination>
                </div>
            </div>
            <!-- /.card -->

        </section>
        <!-- /.content -->
    </div>
</template>

<script>
import axios from "axios";
export default {
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
        this.getBookings();
    },
  computed: {
    currency() {return this.$currency;}
  },
    data() {
        return {
            bookings: {
                total: 0,
            },
          isLoading:false,
          fullPage:false,
          zIndex:10,
        }
    },
    methods: {
        getBookings(page = 1) {
          this.isLoading = true;
          let username = this.$route.params.username;
          axios.get('admin/user-bookings',{params:{username:username,page:page}})
                .then(({data}) => {this.bookings = data;this.isLoading = false})
        },

    },

}
</script>

<style scoped>
.card {
    background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
}
</style>
