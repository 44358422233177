<template>
  <section class="content" style="margin-top: 10px">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header" style="padding-top: 15px">
            <h3 class="card-title text-white">{{this.$route.params.name}}'s Deposits</h3>
            <div class="card-tools">
              <button class="btn btn-secondary btn-sm" @click="getDeposits"><i
                  class="fas fa-sync-alt"></i> Refresh
              </button>
            </div>
          </div>
            <div class="card-header" style="padding-top: 10px">
            <div class="row" style="justify-content: center;">
              <div style="width: 35%; margin-right: 5px; margin-bottom: 10px;">
                <select id="status" class="custom-select" v-model="filter.status" @change="getDeposits">
                  <option value="3" selected>All</option>
                  <option value="0" >Pending</option>
                  <option value="1" >Successful</option>
                  <option value="2" >Rejected</option>
                </select>
              </div>
              <div style="width: 55%; margin-bottom: 10px;">
                <select id="sort-by" class="custom-select" v-model="filter.sortBy" @change="getDeposits">
                  <option value="desc" selected>Latest</option>
                  <option value="asc">Oldest</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body d-flex justify-content-center vld-parent bg-gray-light text-center">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage"
                     :z-index="zIndex"
                     loader="bars">
            </loading>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead class="bg-teal">
                <tr>
                  <th>
                    User
                  </th>
                  <th>
                    Method
                  </th>
                  <th>
                    Amount
                  </th>
                  <th>
                    Submitted
                  </th>
                  <th>
                    Status
                  </th>
                  <th style="text-align: center">
                    Action
                  </th>
                </tr>
                </thead>
                <tbody v-if="parseInt(deposits.total) === 0">
                <td colspan="6">
                  <div class="card-body bg-gray-light text-center text-xl">No Data To Show !!</div>
                </td>
                </tbody>
                <tbody v-else>
                <tr v-for="deposit in deposits.data">
                  <td>
                    {{ deposit.user.name }}
                  </td>
                  <td>
                    {{ deposit.method.name }}
                  </td>
                  <td>
                    {{currency}} {{ deposit.amount }}
                  </td>
                  <td>
                    {{ deposit.date | dateFormat }}
                  </td>
                  <td class="text-center">
                    <a class="btn btn-warning btn-sm" v-if="parseInt(deposit.status) === 0">Pending</a>
                    <a class="btn btn-success btn-sm" v-if="parseInt(deposit.status) === 1">Successful</a>
                    <a class="btn btn-danger btn-sm" v-if="parseInt(deposit.status) === 2">Rejected</a>
                  </td>
                  <td class="project-actions text-center">
                    <button class="btn btn-danger" data-toggle="modal" data-target="#viewInfo"
                            @click="setInfo(deposit)">View Details
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer text-white">
            <span class="ml-3">Total <strong>: </strong></span>
            <span class="float-right mr-3 mr-md-5">{{currency}} {{total_deposits}}</span>
          </div>
          <div class="card-footer d-flex justify-content-center">
            <pagination class="pagination pagination-sm m-0 float-right" :data="deposits"
                        @pagination-change-page="getDeposits"></pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="viewInfo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Deposit Info</h5>
            <button type="button" class="btn btn-dark" data-dismiss="modal" aria-label="Close" id="close">
              <i class="fas fa-times-circle"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <a>User <strong>:</strong> </a>
              <span class="float-right"
                    v-if="info.user.name"><strong>{{ info.user.name }}</strong></span>
            </div>
            <div class="form-group">
              <a>Deposit Method <strong>:</strong> </a>
              <span class="float-right"
                    v-if="info.method.name"><strong>{{ info.method.name }}</strong></span>
            </div>
            <div class="form-group">
              <a>Amount <strong>:</strong> </a>
              <span class="float-right" v-if="info.amount"><strong>{{currency}} {{ info.amount }}</strong></span>
            </div>
            <div class="form-group">
              <a>Status <strong>:</strong> </a>
              <a class="badge badge-warning float-right" v-if="parseInt(info.status) === 0">Pending</a>
              <a class="badge badge-success float-right" v-if="parseInt(info.status) === 1">Successful</a>
              <a class="badge badge-danger float-right" v-if="parseInt(info.status) === 2">Rejected</a>
            </div>
            <div class="form-group">
              <a>Date <strong>:</strong> </a>
              <span class="float-right"
                    v-if="info.date"><strong>{{ info.date | dateFormat }}</strong></span>
            </div>
            <div class="form-group">
              <label>Details</label>
              <p type="text" style="white-space: pre">{{ info.note }}</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import axios from "axios";
export default {
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
    this.getDeposits();
  },
  computed: {
    currency() {return this.$currency;}
  },
  data() {
    return {
      deposits: {
        total:0,
      },
      total_deposits:0,
      info: {
        amount: null,
        note: null,
        date: null,
        status: null,
        method: {
          name: null,
        },
        user: {
          name: null,
        },
      },
      filter:{
        status: '3',
        sortBy: 'desc',
      },
      isLoading:false,
      fullPage:false,
      zIndex:10,
    }
  },
  methods: {
    getDeposits(page = 1) {
      this.isLoading = true;
      let username = this.$route.params.username;
      let status = this.filter.status;
      if (status !== ''){
        status = parseInt(status)
        if (status === 3){
          status = ''
        }
      }
      let sortBy = this.filter.sortBy
      axios.get('admin/user-deposits',{params:{username:username,status:status,sortBy:sortBy,page:page}})
          .then(({data}) => {this.deposits = data.deposits; this.total_deposits = parseFloat(data.sum);this.isLoading = false})
    },
    setInfo(info) {
      this.info = info
    },
  },
}
</script>

<style scoped>
.card {
  background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
}
</style>
