<template>
  <!-- Main content -->
  <section class="content" style="padding-top: 10px">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-navy">
          <div class="card-header">
            <h3 class="card-title">Profile Settings</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body vld-parent" id="editUser">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage"
                     :z-index="zIndex"
                     loader="bars">
            </loading>

            <div class="card">
              <div class="card-header p-2" id="tabHeader">
                <ul class="nav nav-pills">
                  <li class="nav-item"><a class="nav-link text-white active" href="#about" data-toggle="tab">Update Info</a>
                  </li>
                  <li class="nav-item"><a class="nav-link text-white" href="#password" data-toggle="tab">Change Password</a>
                  </li>
                </ul>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="tab-content">
                  <!-- tab-pane -->
                  <div class="active tab-pane" id="about">
                    <form class="form-horizontal">
                      <div class="form-group row">
                        <label for="inputName" class="col-sm-2 col-form-label">Name</label>
                        <div class="col-sm-10">
                          <input type="text" class="form-control" id="inputName"
                                 placeholder="Name" v-model="info.name">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="inputUsername"
                               class="col-sm-2 col-form-label">Username</label>
                        <div class="col-sm-10">
                          <input type="email" class="form-control" id="inputUsername"
                                 placeholder="Username" v-model="info.username">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="inputEmail"
                               class="col-sm-2 col-form-label">Email</label>
                        <div class="col-sm-10">
                          <input type="email" class="form-control" id="inputEmail"
                                 placeholder="Email" v-model="info.email">
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="offset-sm-2 col-sm-10">
                          <button type="submit" class="btn btn-danger" @click.prevent="updateInfo">Submit</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- /.tab-pane -->
                  <!-- tab-pane -->
                  <div class="tab-pane" id="password">
                    <form class="form-horizontal">
                      <div class="form-group row">
                        <label for="new-password" class="col-sm-2 col-form-label">New
                          Password</label>
                        <div class="col-sm-10">
                          <input type="password" class="form-control" id="new-password"
                                 placeholder="New Password" v-model="password.password">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="confirm-password" class="col-sm-2 col-form-label">Confirm
                          Password</label>
                        <div class="col-sm-10">
                          <input type="password" class="form-control" id="confirm-password"
                                 placeholder="Confirm Password" v-model="password.password_confirmation">
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="offset-sm-2 col-sm-10">
                          <button class="btn btn-danger" @click.prevent="updatePassword">Submit</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- /.tab-pane -->

                </div>
                <!-- /.tab-content -->
              </div><!-- /.card-body -->
            </div>

          </div>
          <!-- /.card-body -->

        </div>
        <!-- /.card -->
      </div>

    </div>

  </section>
  <!-- /.content -->
</template>

<script>
import axios from "axios";
export default {
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
    this.getInfo();
  },
  data(){
    return{
      info:{
        name:null,
        email:null,
        username:null,
      },
      password:{
        password: null,
        password_confirmation: null,
      },
      errors:{},
      isLoading:false,
      fullPage:false,
      zIndex:10,
    }
  },
  methods:{
    getInfo(){
      this.isLoading = true;
      axios.get('admin/admin-info')
          .then(({data}) => {
            this.info = data;
            this.isLoading = false;
          })
    },
    updateInfo(){
      axios.patch('admin/update-admin-info',this.info)
          .then(({data})=> {
            this.$toast('success', ''+data.message+'')
            this.getInfo();
          })
      .catch(e =>{
        this.$toast('error', 'Error !!')
      })
    },
    updatePassword(){
      axios.patch('admin/update-password',this.password)
          .then(({data})=> {
            this.$toast('success', ''+data.message+'')
            this.password={password:null,password_confirmation:null};
          })
          .catch(e =>{
            this.$toast('error', 'Error !!')
          })
    },

  }

}
</script>

<style scoped>
.row {
  justify-content: center;
}
#tabHeader{
  background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
}
</style>
