<template>
  <section class="content" style="margin-top: 10px">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header" style="padding-top: 15px">
            <h3 class="card-title text-white">{{this.$route.params.name}}'s Jobs</h3>
            <div class="card-tools">
              <button class="btn btn-secondary btn-sm" @click="getOffers"><i
                  class="fas fa-sync-alt"></i> Refresh
              </button>
            </div>
          </div>
            <div class="card-header" style="padding-top: 10px">
            <div class="row" style="justify-content: center;">
              <div style="width: 35%; margin-right: 5px; margin-bottom: 10px;">
                <select id="status" class="custom-select" v-model="filter.status" @change="getOffers($event)">
                  <option value="10" selected>All</option>
                  <option value="0" >Pending</option>
                  <option value="1" >Active</option>
                  <option value="2" >Completed</option>
                  <option value="3" >Rejected</option>
                  <option value="4" >Full Submitted</option>
                  <option value="5" >On Hold</option>
                </select>
              </div>
              <div style="width: 55%; margin-bottom: 10px;">
                <select id="sort-by" class="custom-select" v-model="filter.sortBy" @change="getOffers($event)">
                  <option value="desc" selected>Latest</option>
                  <option value="asc">Oldest</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body d-flex justify-content-center vld-parent bg-gray-light text-center">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage"
                     :z-index="zIndex"
                     loader="bars">
            </loading>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead class="bg-teal">
                <tr>
                  <th>
                    Title
                  </th>
                  <th>
                    Category
                  </th>
                  <th>
                    Amount
                  </th>
                  <th>
                    Submitted
                  </th>
                  <th>
                    Status
                  </th>
                  <th style="text-align: center">
                    Action
                  </th>
                </tr>
                </thead>
                <tbody v-if="parseInt(offers.total) === 0">
                <td colspan="6">
                  <div class="card-body bg-gray-light text-center text-xl">No Data To Show !!</div>
                </td>
                </tbody>
                <tbody v-else>
                <tr v-for="offer in offers.data">
                  <td>
                    <span v-if="offer.title && offer.title.length<40">{{ offer.title }}</span>
                    <span v-if="offer.title && offer.title.length>40">{{ offer.title.substring(0, 40)+'...'}}</span>
                  </td>
                  <td>
                    {{ offer.category.name }}
                  </td>
                  <td>
                    {{ offer.amount }}
                  </td>
                  <td v-if="moment.utc(offer.created_at).isSameOrAfter(moment.utc().subtract(7,'days').startOf('day'))">
                    {{ offer.created_at | fromNow }}
                  </td>
                  <td v-else>
                    {{offer.created_at | dateFormat}}
                  <td class="text-center">
                    <span class="badge badge-warning" v-if="parseInt(offer.status) === 0">Pending</span>
                    <span class="badge badge-primary" v-if="parseInt(offer.status) === 1">Active</span>
                    <span class="badge badge-success" v-if="parseInt(offer.status) === 2">Completed</span>
                    <span class="badge badge-danger" v-if="parseInt(offer.status) === 3">Rejected</span>
                    <span class="badge badge-secondary" v-if="parseInt(offer.status) === 4">Full Submitted</span>
                    <span class="badge badge-dark" v-if="parseInt(offer.status) === 5">On Hold</span>
                  </td>
                  <td class="project-actions text-center">
                    <button class="btn btn-sm bg-fuchsia" data-toggle="modal" data-target="#viewInfo" @click="setInfo(offer)">Quick Edit</button>
                    /
                    <router-link :to="{name: 'job-details', params:{code:offer.code}}" class="btn btn-sm bg-yellow" data-toggle="modal" data-target="#viewInfo" @click="setInfo(offer)">View Details</router-link>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-center">
            <pagination class="pagination pagination-sm m-0 float-right" :data="offers"
                        @pagination-change-page="getOffers"></pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="viewInfo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Job Info</h5>
            <button type="button" class="btn btn-dark" data-dismiss="modal" aria-label="Close" id="close">
              <i class="fas fa-times-circle"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <a>User <strong>:</strong> </a>
              <span class="float-right"
                    v-if="info.user.name"><strong>{{ info.user.name }}</strong></span>
            </div>
            <div class="form-group">
              <a>Title <strong>:</strong> </a>
              <span class="float-right" v-if="info.title && info.title.length<40"><strong>{{ info.title }}</strong></span>
              <span class="float-right" v-if="info.title && info.title.length>40"><strong>{{ info.title.substring(0, 40) + '...'}}</strong></span>
            </div>
            <div class="form-group">
              <a>Category <strong>:</strong> </a>
              <span class="float-right"
                    v-if="info.category.name"><strong>{{ info.category.name }}</strong></span>
            </div>
            <div class="form-group">
              <a>Price <strong>:</strong> </a>
              <span class="float-right" v-if="info.price"><strong>{{currency}} {{ info.price }}</strong></span>
            </div>
            <div class="form-group">
              <a>Amount <strong>:</strong> </a>
              <span class="float-right" v-if="info.amount"><strong>{{ info.amount }}</strong></span>
            </div>
            <div class="form-group">
              <a>Total Cost <strong>:</strong> </a>
              <span class="float-right" v-if="info.total_price"><strong>{{currency}} {{ info.total_price }}</strong></span>
            </div>
            <div class="form-group">
              <a>Status <strong>:</strong> </a>
              <span class="badge badge-warning float-right" v-if="parseInt(info.status) === 0">Pending</span>
              <span class="badge badge-primary float-right" v-if="parseInt(info.status) === 1">Active</span>
              <span class="badge badge-success float-right" v-if="parseInt(info.status) === 2">Completed</span>
              <span class="badge badge-danger float-right" v-if="parseInt(info.status) === 3">Rejected</span>
              <span class="badge badge-secondary float-right" v-if="parseInt(info.status) === 4">Full Submitted</span>
              <span class="badge badge-dark float-right" v-if="parseInt(info.status) === 5">On Hold</span>
            </div>
            <div class="form-group">
              <a>Date <strong>:</strong> </a>
              <span class="float-right"
                    v-if="info.date"><strong>{{ info.date | dateFormat }}<small> ({{info.date | fromNow}})</small></strong></span>
            </div>
            <div class="form-group" v-if="parseInt(info.status) === 3">
              <a>Reject Note <strong>:</strong> </a>
              <p class="text-red" v-if="info.reject_note">{{info.reject_note}}</p>
            </div>
            <div v-if="parseInt(info.status) !== 0" class="form-group">
              <a >Quick Action <strong>:</strong></a>
              <select class="custom-select" v-model="status">
                <option value="" selected disabled>Select Action</option>
                <option value="1" >Active</option>
                <option value="2" >Completed</option>
                <option value="4" >Full Submitted</option>
                <option value="5" >On Hold</option>
              </select>
            </div>

          </div>
          <div v-if="parseInt(info.status) === 0" class="modal-footer">
            <button class="btn btn-danger mr-1" data-toggle="modal" data-target="#reject">
              Reject Offer
            </button>
            <button class="btn btn-success ml-1" @click="approveOffer">
              Approve Offer
            </button>
          </div>
          <div v-else class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
            <button class="btn btn-success" @click="updateStatus(info.id)" :disabled="!status">Update</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="reject" tabindex="-1" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Reject Offer</h5>
            <button type="button" class="btn btn-dark" data-dismiss="modal" aria-label="Close" id="close-reject">
              <i class="fas fa-times-circle"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="text-center bg-warning">
              <h2 class="text-white">Important Note !!</h2>
            </div>
            <div class="text-center bg-yellow">
              <p>If you reject this offer, user will be refunded the full amount he/she paid for this task. Make sure to write a reject note so user can see why his/her offer getting rejected.</p>
            </div>
            <div class="form-group">
              <label for="user">Reject Note :</label>
              <textarea type="text" id="user" class="form-control" v-model="reject_note"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-danger" @click="rejectOffer">Submit</button>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
    this.getOffers();
  },
  computed: {
    currency() {return this.$currency;}
  },
  data() {
    return {
      moment,
      offers: {
        total:0,
      },
      info: {
        id: null,
        title: null,
        price: null,
        total_price: null,
        amount: null,
        date: null,
        status: null,
        user_id: null,
        category_id: null,
        reject_note: null,
        user: {
          name: null,
        },
        category: {
          name: null,
        },
      },
      filter:{
        status: '10',
        sortBy: 'desc',
      },
      status: '',
      reject_note: null,
      isLoading:false,
      fullPage:false,
      zIndex:10,
    }
  },
  methods: {
    getOffers(page = 1) {
      this.isLoading = true;
      let username = this.$route.params.username;
      let status = this.filter.status;
      if (status !== ''){
        status = parseInt(status)
        if (status === 10){
          status = ''
        }
      }
      let sortBy = this.filter.sortBy
      axios.get('admin/user-offers',{params:{username:username,status:status,sortBy:sortBy,page:page}})
          .then(({data}) => {this.offers = data;this.isLoading = false})
    },
    setInfo(info) {
      this.info = info
      if (parseInt(this.info.status) !== 3){
        this.status = this.info.status;
      }
    },
    updateStatus(id){
      axios.post('admin/offer-status-update',{id:id,status:this.status})
          .then(({data})=> {
            this.getOffers();
            this.$toast('success', ''+data.message+'')
          })
      this.toggleModal();
    },
    approveOffer(){
      axios.post('admin/offer-status-update',{id:this.info.id,status:1,approve:1})
          .then(({data})=> {
            this.getOffers();
            this.$toast('success', ''+data.message+'')

          })
      this.toggleModal();
    },
    rejectOffer(){
      let note = this.reject_note;
      axios.post('admin/offer-status-update',{id:this.info.id,status:3,note:note})
          .then(({data})=> {
            this.getOffers();
            this.$toast('success', ''+data.message+'');
            document.getElementById('close-reject').click();
          })
      this.toggleModal();
    },
    toggleModal(){
      document.getElementById('close').click();
    },

  },
}
</script>

<style scoped>
.card {
  background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
}
</style>
