<template>
  <footer class="main-footer text-center text-white" v-if="isLoggedIn">
    <strong>&copy; {{this.$sitename }} </strong>~ 2021 | All rights reserved.
  </footer>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn'
    })
  },
}
</script>

<style scoped>
.main-footer{
  background-image: radial-gradient( circle farthest-corner at -4% -12.9%,  rgba(74,98,110,1) 0.3%, rgba(30,33,48,1) 90.2% );
}
</style>