<template>
  <div class="hold-transition sidebar-mini layout-fixed">
    <div class="wrapper">
      <layout-header/>
      <layout-sidebar/>
      <div class="content-wrapper">
        <router-view></router-view>
      </div>
      <layout-footer/>
    </div>
  </div>
</template>

<script>
import layoutHeader from "@/views/ui/layoutHeader";
import layoutSidebar from "@/views/ui/layoutSidebar";
import layoutFooter from "@/views/ui/layoutFooter";
export default {
components:{layoutHeader, layoutSidebar, layoutFooter},
}
</script>

<style scoped>

</style>