<template>
  <div id="app">
    <div>
      <auth-layout v-if="($route.path === '/login' || $route.path === '/activate')"></auth-layout>
      <main-layout v-else></main-layout>
    </div>
  </div>
</template>
<script>
import mainLayout from "@/layouts/mainLayout";
import authLayout from "@/layouts/authLayout";
export default {
  components: {mainLayout, authLayout},
  beforeCreate() {
    this.$store.dispatch('setAuth');
  },
}
</script>
<style>

</style>
