<template>
  <section class="content" style="margin-top: 10px">
    <div class="container-fluid">
      <!-- Small boxes (Stat box) -->

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header bg-secondary">
              <div class="card-title">{{moment.utc().format('hh:mm A, Do MMM, YYYY')}}</div>
              <div class="card-tools">
                <button class="btn btn-secondary btn-sm" @click="getInfo"><i
                    class="fas fa-sync-alt"></i> Refresh
                </button>
              </div>
            </div>
            <div class="card-body vld-parent">
              <loading :active.sync="isLoading"
                       :can-cancel="false"
                       :is-full-page="fullPage"
                       :z-index="zIndex"
                       loader="bars">
              </loading>
              <div class="row">
                <div class="col-lg-3 col-6">
                  <router-link to="/jobs" class="small-box bg-success">
                    <div class="inner">
                      <h3>{{pending.offers}}</h3>
                      <p>Pending Jobs</p>
                    </div>
                    <div class="icon">
                      <i class="fas fa-briefcase"></i>
                    </div>
                  </router-link>
                </div>

                <div class="col-lg-3 col-6">
                  <router-link to="/complaints" class="small-box bg-navy">
                    <div class="inner">
                      <h3>{{pending.complaints}}</h3>
                      <p>Active Complaints</p>
                    </div>
                    <div class="icon">
                      <i class="fas fa-flag"></i>
                    </div>
                  </router-link>
                </div>

                <div class="col-lg-3 col-6">
                  <router-link to="/deposits" class="small-box bg-warning">
                    <div class="inner">
                      <h3>{{ pending.deposits }}</h3>
                      <p>Pending Deposits</p>
                    </div>
                    <div class="icon">
                      <i class="fas fa-piggy-bank"></i>
                    </div>
                  </router-link>
                </div>

                <div class="col-lg-3 col-6">
                  <router-link to="/withdrawals" class="small-box bg-danger">
                    <div class="inner">
                      <h3>{{ pending.withdrawals }}</h3>
                      <p>Pending Withdrawals</p>
                    </div>
                    <div class="icon">
                      <i class="fas fa-hand-holding-usd"></i>
                    </div>
                  </router-link>
                </div>

              </div>

          </div>
          <!-- ./col -->
        </div>
      </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header bg-purple">
              <div class="card-title">Announcement</div>
              <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="card-body vld-parent">
              <loading :active.sync="isAnnLoading"
                       :can-cancel="false"
                       :is-full-page="fullPage"
                       :z-index="zIndex"
                       loader="bars">
              </loading>
                <div class="form-group">
                  <a>Active <strong>:</strong> </a>
                  <switches class="float-right rounded-pill" v-model="announcement.status" />
                </div>

                <div class="form-group">
                  <label >Message : </label>
                  <vue-editor id="inputDescription" v-model="announcement.text" :editorToolbar="customToolbar" />
                </div>

              <hr>
              <div class="social-auth-links text-right">
                <button class="w-25 btn btn-success" @click.prevent="annUpdate">Submit</button>
              </div>
          </div>
          <!-- ./col -->
        </div>
      </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header bg-dark">
              <div class="card-title">Statistics</div>
              <div class="card-tools">
                <select id="sort-by" class="custom-select" v-model.number="statId" @change="getInfo">
                  <option value="1" selected>Today</option>
                  <option value="2">Month</option>
                  <option value="3">Total</option>
                </select>
              </div>
            </div>
            <div class="card-body vld-parent">
              <loading :active.sync="isLoading"
                       :can-cancel="false"
                       :is-full-page="fullPage"
                       :z-index="zIndex"
                       loader="bars">
              </loading>
              <div class="row">

                <div class="col-lg-3 col-6">
                  <router-link to="/users" class="small-box bg-olive">
                    <div class="inner">
                      <h3>{{stats.users | formatNumber}}</h3>
                      <p>{{ this.statName }} Users</p>
                    </div>
                    <div class="icon">
                      <i class="fas fa-users"></i>
                    </div>
                  </router-link>
                </div>

                <div class="col-lg-3 col-6">
                  <router-link to="/jobs" class="small-box bg-maroon">
                    <div class="inner">
                      <h3>{{stats.offers | formatNumber}}</h3>
                      <p>{{ this.statName }} Jobs</p>
                    </div>
                    <div class="icon">
                      <i class="fas fa-briefcase"></i>
                    </div>
                  </router-link>
                </div>

                <div class="col-lg-3 col-6">
                  <router-link to="/deposits" class="small-box bg-secondary">
                    <div class="inner">
                      <h3>{{currency}}<span class="text-xl">{{ stats.deposits | formatNumber }}</span></h3>
                      <p>{{ this.statName }} Deposits</p>
                    </div>
                    <div class="icon">
                      <i class="fas fa-piggy-bank"></i>
                    </div>
                  </router-link>
                </div>

                <div class="col-lg-3 col-6">
                  <router-link to="/withdrawals" class="small-box bg-fuchsia">
                    <div class="inner">
                      <h3>{{currency}}<span class="text-xl">{{ stats.withdrawals | formatNumber }}</span></h3>
                      <p>{{ this.statName }} Withdrawals</p>
                    </div>
                    <div class="icon">
                      <i class="fas fa-hand-holding-usd"></i>
                    </div>
                  </router-link>
                </div>

                <div class="col-lg-3 col-6">
                  <router-link to="/complaints" class="small-box bg-cyan">
                    <div class="inner">
                      <h3>{{ stats.complaints | formatNumber }}</h3>
                      <p>{{ this.statName }} Complaints</p>
                    </div>
                    <div class="icon">
                      <i class="fas fa-flag"></i>
                    </div>
                  </router-link>
                </div>

              </div>
            </div>
          </div>
          <!-- ./col -->
        </div>
      </div>

      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import switches from "@/views/shared/switches";
import {VueEditor} from "vue2-editor";

export default {
  components:{switches, VueEditor},
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
    this.getInfo();
    this.getAnn();
  },
  computed: {
    currency() {return this.$currency;},
    statName(){
      let id = this.statId;
      if (id == 1){
        return 'Today'
      } else {
        if (id == 2){
          return 'Monthly'
        } else {
          if (id == 3){
            return 'Total'}
        }}},
  },
  data() {
    return {
      moment,
      announcement:{
        status: false,
        text:'',
      },
      statId:1,
      pending:{
        offers:0,
        complaints:0,
        deposits:0,
        withdrawals:0,
      },
      stats:{
        users:0,
        offers:0,
        complaints:0,
        deposits:0,
        withdrawals:0,
      },
      customToolbar: [
        [{
          header: [false, 1, 2, 3]
        }], ["bold", "italic", "underline", "clean"],
        [{align: ""}, {align: "center"}, {align: "right"}, {align: "justify"}], ["code-block","blockquote","link","video"],
        [{list: "ordered"}, {list: "bullet"},{ list: "check" }], [{indent: "-1"}, {indent: "+1"}], [{color: []}, {background: []}]
      ],
      isLoading:false,
      isAnnLoading:false,
      fullPage:false,
      zIndex:10,
    }
  },
  methods: {
    getInfo() {
      this.isLoading = true;
      axios.get('admin/dashboard-info',{params:{stat:this.statId}})
          .then(({data}) => {this.stats = data.stats;this.pending = data.pending; this.isLoading = false})
    },
    getAnn(){
      this.isAnnLoading = true;
      axios.get('admin/ann-info')
          .then(({data}) => {this.announcement.status = data.status; this.announcement.text = data.text;this.isAnnLoading = false})
    },
    annUpdate(){
      axios.patch('admin/ann-update',this.announcement)
          .then(({data})=> {
            this.$toast('success', ''+data.message+'')
          })
    },

  },

}
</script>

<style scoped>

</style>