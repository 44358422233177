import Vue from 'vue';
import Vuex from "vuex";
import axios from "axios";
import {authCheck} from "./auth"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    isLoggedIn: false,
  },
  getters:{
    isLoggedIn(state){
      return state.isLoggedIn
    },
  },
  mutations: {
    setToken (state, token){
      state.token = token
    },
    setLoggedIn(state, status){
      state.isLoggedIn = status
    }
  },
  actions: {
    async signIn({ dispatch }, credentials){
      let response = await axios.post('admin/login', credentials)
      return   dispatch('attempt', response.data.token)
    },

    async attempt({ commit, state }, token){
      if (token){
        commit('setToken', token)
      }
      if (!state.token) {
        return
      }
      try {
        await axios.get('admin/verify')
        localStorage.setItem('isLoggedIn', 'true')
        commit('setLoggedIn', true)
      } catch (e){
        localStorage.removeItem('isLoggedIn')
        commit('setLoggedIn', false)
        commit('setToken', null)
      }
    },

    setAuth({commit}){
      commit('setLoggedIn', authCheck())
    },

    signOut({commit}){
      return axios.post('admin/logout').then(()=> {
        localStorage.removeItem('isLoggedIn')
        commit('setLoggedIn', false)
        commit('setToken', null)
      })
    },

  },


})
