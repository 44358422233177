<template>
  <div v-if="isLoggedIn">
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand" id="navbar">
      <!-- Left navbar links -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link text-white" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars fa-2x"></i></a>
        </li>
      </ul>

      <!-- Left navbar links -->
      <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown">
          <a class="nav-link text-white" data-toggle="dropdown" href="#" aria-expanded="false">
            <i class="fas fa-user fa-2x"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" style="left: inherit; right: 0px;">
            <router-link to="/" class="dropdown-item">
              <i class="fas fa-desktop"></i> Dashboard
            </router-link>
            <div class="dropdown-divider"></div>
            <router-link to="/profile" class="dropdown-item">
              <i class="fas fa-user-circle"></i> Profile
            </router-link>
            <div class="dropdown-divider"></div>
            <router-link to="/settings" class="dropdown-item">
              <i class="fas fa-sliders-h"></i> Settings
            </router-link>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item" @click.prevent="signOut">
              <i class="fas fa-sign-out-alt"></i> Logout
            </button>
          </div>
        </li>
      </ul>

    </nav>
    <!-- /.navbar -->
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {mapGetters} from 'vuex';
export default {
  computed:{
    ...mapGetters({
      isLoggedIn: 'isLoggedIn'
    })
  },
  methods:{
    ...mapActions({
      signOutAction: 'signOut'
    }),
    signOut(){
      this.signOutAction().then(()=>{
        this.$router.replace({name: 'login'})
      })
    },
    // toggle(){
    //   if (this.sidebar){
    //     this.$jq("body").addClass('sidebar-collapse').trigger('collapsed.pushMenu');
    //     this.sidebar = false;
    //   }else {
    //     this.$jq("body").removeClass('sidebar-collapse').trigger('expanded.pushMenu');
    //     this.sidebar = true;
    //   }},
  }
}
</script>

<style scoped>
#navbar{
  background-image: radial-gradient( circle farthest-corner at -4% -12.9%,  rgba(74,98,110,1) 0.3%, rgba(30,33,48,1) 90.2% );
}
</style>