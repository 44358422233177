<template>
    <div>
        <!-- Content Header (Page header) -->
        <!-- Main content -->
        <section class="content" style="margin-top: 10px">

            <div class="card">
                <div class="card-header" style="padding-top: 15px">
                    <h3 class="card-title text-white">{{this.$route.params.name}}'s Referrals</h3>
                    <div class="card-tools">
                        <button class="btn btn-secondary btn-sm" @click="getReferrals"><i class="fas fa-sync-alt"></i>
                            Refresh
                        </button>
                    </div>
                </div>
                <div class="card-body bg-gray-light vld-parent">
                  <loading :active.sync="isLoading"
                           :can-cancel="false"
                           :is-full-page="fullPage"
                           :z-index="zIndex"
                           loader="bars">
                  </loading>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead class="bg-teal">
                            <tr>
                                <th class="text-center">
                                    User
                                </th>
                                <th class="text-center">
                                    Withdraw Reward
                                </th>
                                <th class="text-center">
                                    Offer Reward
                                </th>
                                <th class="text-center">
                                    Joined At
                                </th>
                            </tr>
                            </thead>
                            <tbody v-if="parseInt(referrals.total) === 0">
                            <td colspan="4">
                                <div class="d-block justify-content-center text-center">
                                    <div class="card">
                                        <div class="card-body empty-info text-center">
                                            <h2 class="text-white">No Referrals Found !</h2>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            </tbody>
                            <tbody v-else>
                            <tr v-for="ref in referrals.data" :key="ref.username">
                                <td class="text-center">
                                    <router-link :to="{name: 'user-details', params:{username:ref.username}}">{{ ref.name }}
                                    </router-link>
                                </td>
                                <td class="text-center" v-if="parseInt(ref.ref_wr) === 1">
                                    <span class="badge badge-success">Yes</span>
                                </td>
                                <td class="text-center" v-if="parseInt(ref.ref_wr) === 0">
                                    <span class="badge badge-danger">No</span>
                                </td>
                                <td class="text-center" v-if="parseInt(ref.ref_or) === 1">
                                    <span class="badge badge-success">Yes</span>
                                </td>
                                <td class="text-center" v-if="parseInt(ref.ref_or) === 0">
                                    <span class="badge badge-danger">No</span>
                                </td>
                                <td class="text-center">
                                   {{ref.created_at | dateFormat}}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- /.card-body -->
                <div class="card-footer clearfix">
                    <pagination class="pagination pagination-sm m-0 float-right" :data="referrals"
                                @pagination-change-page="getReferrals"></pagination>
                </div>
            </div>
            <!-- /.card -->

        </section>
        <!-- /.content -->
    </div>
</template>

<script>
import axios from "axios";
export default {
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
    created() {
        this.getReferrals();
    },
    data() {
        return {
            referrals: {
                total: 0,
            },
          isLoading:false,
          fullPage:false,
          zIndex:10,
        }
    },
    methods: {
        getReferrals(page = 1) {
          this.isLoading = true;
          let username = this.$route.params.username;
            axios.get('admin/user-referrals',{params:{username:username,page:page}})
                .then(({data}) => {
                    this.referrals = data;
                  this.isLoading = false
                })
    }
        },
}
</script>

<style scoped>
.card {
    background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
}
</style>
