<template>
  <!-- Main content -->
  <section class="content vld-parent" style="margin-top: 10px">
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"
             :z-index="zIndex"
             loader="bars">
    </loading>
    <!-- Default box -->
    <div class="card">
      <div class="card-header">
        <h3 class="card-title text-white">Job Details</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-12 col-lg-4 order-1 order-md-2">

            <div class="card">
              <div class="card-header bg-teal">
                <h3 class="card-title text-white">{{ task_info.title }}</h3>
              </div>
              <div class="card-body bg-gray-light">
                <div style="margin-left: 20px">
                  <p class="text">User:
                    <router-link :to="{name:'user-details',params:{username:task_info.user.username}}" class="badge badge-dark ml-2 p-2">
                      <b>{{ task_info.user.name }}</b>
                    </router-link>
                  </p>
                  <p class="text">Category:
                    <b class="d-block">{{ task_info.category.name }}</b>
                  </p>
                  <p class="text">Time:
                    <b class="d-block">{{ task_info.time }} minutes</b>
                  </p>
                  <p class="text">Total:
                    <b class="d-block">{{ task_info.amount }} unit</b>
                  </p>
                  <p class="text">Paid:
                    <b class="d-block">{{currency}} {{ task_info.total_price }}</b>
                  </p>
                  <p class="text">Date:
                    <b class="d-block">{{ task_info.date | dateFormat }}</b>
                  </p>
                </div>
                <div v-if="parseInt(task_info.status) === 0" style="margin-left: 20px">
                  <a >Quick Action <strong>:</strong></a>
                  <div class="text-center mt-2">
                    <button class="btn btn-danger mr-1" data-toggle="modal" data-target="#reject">
                      Reject Offer
                    </button>
                    <button class="btn btn-success ml-1" @click="approveOffer">
                      Approve Offer
                    </button>
                  </div>
                </div>
                <div v-else style="margin-left: 20px">
                  <a >Change Status <strong>:</strong></a>
                  <div class="text-center mt-2">
                    <select class="custom-select rounded shadow" v-model="status">
                      <option value="" selected disabled>Select Action</option>
                      <option value="1" >Active</option>
                      <option value="2" >Completed</option>
                      <option value="4" >Full Submitted</option>
                      <option value="5" >On Hold</option>
                    </select>
                    <button class="btn btn-success" @click="updateStatus" style="margin-top: 15px" :disabled="!status">Update</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-12 col-lg-8 order-2 order-md-1">
            <div class="row mt-2">
              <div class="col-12 col-sm-2">
                <div class="info-box bg-gradient-maroon">
                  <div class="info-box-content">
                    <span class="info-box-text text-center text-white">Available</span>
                    <span class="info-box-number text-center text-white mb-0">{{ availableAmount }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-2">
                <div class="info-box bg-gradient-info">
                  <div class="info-box-content">
                    <span class="info-box-text text-center text-white">Booked</span>
                    <span class="info-box-number text-center text-white mb-0">{{ task_info.booked }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-2">
                <div class="info-box bg-gradient-purple">
                  <div class="info-box-content">
                    <span class="info-box-text text-center text-white">Submitted</span>
                    <span class="info-box-number text-center text-white mb-0">{{ task_info.submitted }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-3">
                <div class="info-box bg-gradient-success">
                  <div class="info-box-content">
                    <span class="info-box-text text-center text-white">Completed</span>
                    <span class="info-box-number text-center text-white mb-0">{{
                        task_info.completed
                      }} / {{ task_info.amount }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-3">
                <div class="info-box bg-gradient-pink">
                  <div class="info-box-content">
                    <span class="info-box-text text-center text-white">Status</span>
                    <span class="text-center rounded bg-warning" v-if="parseInt(task_info.status) === 0">Pending</span>
                    <span class="text-center rounded bg-primary" v-if="parseInt(task_info.status) === 1">Active</span>
                    <span class="text-center rounded bg-success" v-if="parseInt(task_info.status) === 2">Completed</span>
                    <span class="text-center rounded bg-danger" v-if="parseInt(task_info.status) === 3">Rejected</span>
                    <span class="text-center rounded bg-danger" v-if="parseInt(task_info.status) === 4">Full Submitted</span>
                    <span class="text-center rounded bg-danger" v-if="parseInt(task_info.status) === 5">On Hold</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card2 text-center">
                  <div class="card-header bg-teal">
                    <h4 class="text-white">Instructions</h4>
                  </div>
                  <div class="card-body d-flex justify-content-center bg-gray-light">
                    <div v-html="task_info.details">

                    </div>
                  </div>
                  <div class="card-footer text-center bg-teal">
                    <div class="text-center">
                      <a :href="'//'+task_info.action_url" target="_blank" type="button"
                         class="btn btn-warning col-6 text-center"> Action Url </a>
                    </div>
                    <div>
                      <p class="text">{{ task_info.action_url }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-12 order-3 order-md-3">
            <div class="row mt-2">
              <div class="col-12">
                <div class="card" id="card2">
                  <div class="card-header" style="padding-top: 15px">
                    <h2 class="card-title text-white"><strong>Submitted Proofs</strong></h2>
                    <div class="card-tools">
                      <button class="btn btn-secondary btn-sm" @click="getProofs"><i
                          class="fas fa-sync-alt"></i>
                        Refresh
                      </button>
                    </div>
                  </div>
                  <div class="card-header" style="padding-top: 10px">
                    <div class="row" style="justify-content: center;">
                      <div style="width: 35%; margin-right: 5px; margin-bottom: 10px;">
                        <select id="status" class="custom-select" v-model="filter.status" @change="getProofs">
                          <option value="10" selected>All</option>
                          <option value="0" >Pending</option>
                          <option value="1" >Approved</option>
                          <option value="2" >Rejected</option>
                          <option value="3" >In Review</option>
                        </select>
                      </div>
                      <div style="width: 55%; margin-bottom: 10px;">
                        <select id="sort-by" class="custom-select" v-model="filter.sortBy" @change="getProofs">
                          <option value="desc" selected>Latest</option>
                          <option value="asc">Oldest</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="card-body text-center bg-gray-light vld-parent">
                    <loading :active.sync="isProofLoading"
                             :can-cancel="false"
                             :is-full-page="fullPage"
                             :z-index="zIndex"
                             loader="bars">
                    </loading>
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead class="bg-teal">
                        <tr>
                          <th>
                            User Name
                          </th>
                          <th>
                            Status
                          </th>
                          <th>
                            Submitted
                          </th>
                          <th style="text-align: center">
                            Action
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="proof in proofs.data" :key="proof.id">
                          <td>
                            <!--                            <router-link :to="{name: 'user-info', params:{username:proof.user.username}}" class="text-white">-->
                            <b class="d-block"><span class="badge badge-dark p-1">{{proof.user.name }}</span></b>
                            <!--                            </router-link>-->
                          </td>
                          <td class="text-center">
                            <a class="btn btn-warning btn-sm" v-if="parseInt(proof.status) === 0">Pending</a>
                            <a class="btn btn-success btn-sm" v-if="parseInt(proof.status) === 1">Approved</a>
                            <a class="btn btn-danger btn-sm" v-if="parseInt(proof.status) === 2">Rejected</a>
                            <a class="btn btn-primary btn-sm" v-if="parseInt(proof.status) === 3">In Review</a>
                          </td>
                          <td v-if="moment.utc(proof.created_at).isSameOrAfter(moment.utc().subtract(7,'days').startOf('day'))">
                            {{ proof.created_at | fromNow }}
                          </td>
                          <td v-else>
                            {{proof.created_at | dateFormat}}
                          </td>
                          <td class="project-actions text-center">
                            <button class="btn btn-danger" data-toggle="modal"
                                    data-target="#view" @click="setProof(proof)">
                              View Details
                            </button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="card-footer d-flex justify-content-center">
                    <pagination class="pagination pagination-sm m-0 float-right" :data="proofs"
                                @pagination-change-page="getProofs"></pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->

    <div class="modal fade" id="view" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Proof Details</h5>
            <button type="button" class="btn btn-dark" data-dismiss="modal" aria-label="Close" id="close">
              <i class="fas fa-times-circle"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <a>Submitted <strong>:</strong> </a>
              <span class="float-right" v-if="selected.created_at"><strong>{{ selected.created_at | dateFormat }}<small>({{selected.created_at | fromNow}})</small></strong></span>
            </div>
            <div class="form-group">
              <label>Note</label>
              <p style="white-space: pre">{{ selected.comment }}</p>
            </div>
            <div class="form-group">
              <div id="proofImage" v-if="selected.images.length > 1">
                <expandable-image v-for="(image,index) in selected.images" :key="index" :src="url+image"
                                  :close-on-background-click="true" alt="" style="padding: 5px"/>
              </div>
              <div v-else class="w-50 px-2 py-2 justify-content-center">
                <expandable-image v-for="(image,index) in selected.images" :key="index" :src="url+image"
                                  :close-on-background-click="true" alt="" style="padding: 5px"/>
              </div>
            </div>

          </div>
          <div class="modal-footer justify-content-center" v-if="parseInt(selected.status) === 1">
            <a class="btn btn-success">Proof was Approved</a>
          </div>
          <div class="modal-footer justify-content-center text-center" v-else-if="parseInt(selected.status) === 2">
            <a class="btn btn-danger">Proof was Rejected</a>
          </div>
          <div class="modal-footer justify-content-center" v-if="parseInt(selected.status) === 3">
            <a class="btn btn-warning">Proof is reported by user</a>
          </div>
          <div class="modal-footer justify-content-center" v-if="parseInt(selected.status) === 0">
            <a class="btn btn-warning">Proof is pending for review</a>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="reject" tabindex="-1" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Reject Offer</h5>
            <button type="button" class="btn btn-dark" data-dismiss="modal" aria-label="Close" id="close-reject">
              <i class="fas fa-times-circle"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="text-center bg-warning">
              <h2 class="text-white">Important Note !!</h2>
            </div>
            <div class="text-center bg-yellow">
              <p>If you reject this offer, user will be refunded the full amount he/she paid for this task. Make sure to write a reject note so user can see why his/her offer getting rejected.</p>
            </div>
            <div class="form-group">
              <label for="user">Reject Note :</label>
              <textarea type="text" id="user" class="form-control" v-model="reject_note"
                        :disabled="isLoading"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-danger" @click="rejectOffer">Submit</button>
          </div>
        </div>
      </div>
    </div>

  </section>
  <!-- /.content -->
</template>

<script>
import axios from "axios";
import ExpandableImage from "../../shared/ExpandableImage";
import moment from "moment";

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
    this.getTaskInfo();
    this.getProofs();
  },
  data() {
    return {
      url: this.$siteurl,
      moment,
      task_info: {
        id: null,
        amount: 0,
        completed: 0,
        submitted: 0,
        booked: 0,
        time: 0,
        status: null,
        action_url: null,
        total_price: null,
        user:{name:null},
        category:{name:null},
      },
      proofs: {},
      selected: {
        comment: '',
        created_at: null,
        images: [],
      },
      status: '',
      reject_note: null,
      filter:{
        status: '10',
        sortBy: 'desc',
      },
      isLoading:false,
      isProofLoading:false,
      fullPage:false,
      zIndex:10,
    }
  },
  components: {ExpandableImage},
  computed: {
    availableAmount() {
      return parseInt(this.task_info.amount) - (parseInt(this.task_info.completed) + parseInt(this.task_info.submitted) + parseInt(this.task_info.booked));
    },
    currency(){
      return this.$currency;
    },
  },
  methods: {
    getTaskInfo() {
      this.isLoading = true;
      let code = this.$route.params.code
      axios.get('admin/offer-details/' + code)
          .then(({data}) => {
            this.task_info = data;
            if (parseInt(this.task_info.status) !== 3){
              this.status = this.task_info.status;
            }
            this.isLoading = false
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.status === 404 || 401) {
              this.$router.replace({name: 'notFound'})
            }
          })
    },
    getProofs(page = 1) {
      this.isProofLoading = true;
      let code = this.$route.params.code
      let status = this.filter.status;
      if (status !== ''){
        status = parseInt(status)
        if (status === 10){
          status = ''
        }
      }
      let sortBy = this.filter.sortBy
      axios.get('admin/get-proofs?code=' + code + '&status=' + status + '&sortBy=' + sortBy + '&page=' + page)
          .then(({data}) => {this.proofs = data;this.isProofLoading = false})
    },
    setProof(proof) {
      this.selected = proof
      this.selected.images = JSON.parse(this.selected.images)
    },
    updateStatus(){
      axios.post('admin/offer-status-update',{id:this.task_info.id,status:this.status})
          .then(({data})=> {
            this.getTaskInfo();
            this.$toast('success', ''+data.message+'')
          })
    },
    approveOffer(){
      axios.post('admin/offer-status-update',{id:this.task_info.id,status:1,approve:1})
          .then(({data})=> {
            this.getTaskInfo();
            this.$toast('success', ''+data.message+'')
          })
    },
    rejectOffer(){
      let note = this.reject_note;
      axios.post('admin/offer-status-update',{id:this.task_info.id,status:3,note:note})
          .then(({data})=> {
            this.getTaskInfo();
            this.$toast('success', ''+data.message+'');
            document.getElementById('close-reject').click();
      })
    },
    toggleModal() {
      document.getElementById('close').click();
    },
  },

}
</script>

<style scoped>
.card {
  background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
}

#card2 {
  background-image: linear-gradient(109.6deg, rgba(48, 207, 208, 1) 11.2%, rgba(51, 8, 103, 1) 92.5%);
}
#proofImage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  max-width: 100%;
  position: relative;
}
</style>
