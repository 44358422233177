<template>
  <!-- Main content -->
  <section class="content" style="margin-top: 10px">

    <div class="row">
      <div class="col-md-12">
        <div class="card card-navy">
          <div class="card-header">
            <h3 class="card-title">Add Faq</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body bg-gray-light" id="editUser">
            <div class="row justify-content-start">

              <div class="col-md-5">
                <small class="text-danger" v-if="errors.question">{{ errors.question[0] }}</small>
                <label>Question :</label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" placeholder="Write Question" v-model="info.question">
                </div>
              </div>
              <div class="col-md-7">
                <label>Answer :</label>
                <small class="text-danger" v-if="errors.answer">{{ errors.answer[0] }}</small>
                <div class="input-group mb-3">
                  <textarea type="url" class="form-control" placeholder="Write question answer" v-model="info.answer" />
                </div>
              </div>

            </div>
            <hr>
            <div class="text-right">
              <button class="col-4 col-md-3 col-lg-2 btn btn-success" @click.prevent="addFaq">Create</button>
            </div>

          </div>
          <!-- /.card-body -->

        </div>
        <!-- /.card -->
      </div>

    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card card-navy">
          <div class="card-header">
            <h3 class="card-title">Faqs</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body vld-parent" id="faqs">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage"
                     :z-index="zIndex"
                     loader="bars">
            </loading>
            <div class="row">
              <div class="col-12" id="accordion">

                <div v-if="parseInt(faqs.total) === 0" class="card card-warning card-outline">
                  <a class="d-block w-100" data-toggle="collapse" href="#collapseNone">
                    <div class="card-header">
                      <h4 class="card-title w-100">No Faq Question Available</h4>
                    </div>
                  </a>
                  <div id="collapseNone" class="collapse show" data-parent="#accordion">
                    <div class="card-body">
                      Please add questions to see here.
                    </div>
                  </div>
                </div>
                <div v-else class="card" v-for="(faq,index) in faqs.data" :key="faq.id">
                  <a class="d-block w-100" data-toggle="collapse" :href="'#collapse'+index">
                    <div class="card-header bg-teal">
                      <h4 class="card-title">Q{{index+1}}: {{faq.question}}</h4>
                      <div class="card-tools bg-white rounded ju">
                        <button type="button" class="btn btn-sm text-red" @click.prevent="deleteFaq(faq.id)"><strong>X</strong></button>
                      </div>
                    </div>
                  </a>
                  <div :id="'collapse'+index" class="collapse show" data-parent="#accordion">
                    <div class="card-body">
                      {{faq.answer}}
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </section>
  <!-- /.content -->
</template>

<script>
import axios from "axios";
export default {
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
    this.getFaq();
  },
  data() {
    return {
      faqs:{
        total:0,
      },
      info: {
        question:null,
        answer:null,
      },
      errors:{},
      isLoading:false,
      fullPage:false,
      zIndex:10,
    }
  },
  methods: {
    getFaq() {
      this.isLoading = true;
      axios.get('faqs')
      .then(({data})=>{
        this.faqs = data;
        this.isLoading = false;
      })
    },
    addFaq(){
      axios.post('admin/add-faq',this.info)
          .then(({data})=>{
            this.info ={question:null,answer:null};
            this.$toast('success', ''+data.message+'');
            this.getFaq();
          })
    },
    deleteFaq(id){
        axios.post('admin/delete-faq',{id:id})
            .then(({data})=>{
              this.$toast('success', ''+data.message+'');
              this.getFaq();
            })
    },
  },
}
</script>

<style scoped>

</style>