<template>
  <section class="content" style="margin-top: 10px">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header" style="padding-top: 15px">
            <h3 class="card-title text-white">Complaints</h3>
            <div class="card-tools">
              <button class="btn btn-secondary btn-sm" @click="getComplaints"><i
                  class="fas fa-sync-alt"></i> Refresh
              </button>
            </div>
          </div>
            <div class="card-header" style="padding-top: 10px">
            <div class="row" style="justify-content: center;">
              <div style="width: 35%; margin-right: 5px; margin-bottom: 10px;">
                <select id="status" class="custom-select" v-model="filter.status" @change="getComplaints">
                  <option value="3" selected>All</option>
                  <option value="1" >Active</option>
                  <option value="0" >Closed</option>
                  <option value="2" >Successful</option>
                </select>
              </div>
              <div style="width: 25%; margin-bottom: 10px;">
                <select id="sort-by" class="custom-select" v-model="filter.sortBy" @change="getComplaints">
                  <option value="desc" selected>Latest</option>
                  <option value="asc">Oldest</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body d-flex justify-content-center vld-parent bg-gray-light text-center">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage"
                     :z-index="zIndex"
                     loader="bars">
            </loading>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead class="bg-teal">
                <tr>
                  <th>
                    User
                  </th>
                  <th>
                    Details
                  </th>
                  <th>
                    Status
                  </th>
                  <th>
                    Submitted
                  </th>
                  <th style="text-align: center">
                    Action
                  </th>
                </tr>
                </thead>
                <tbody v-if="parseInt(complaints.total) === 0">
                <td colspan="5">
                  <div class="card-body bg-gray-light text-center text-xl">No Data To Show !!</div>
                </td>
                </tbody>
                <tbody v-else>
                <tr v-for="complaint in complaints.data">
                  <td>
                    {{ complaint.user.name }}
                  </td>
                  <td>
                    <span v-if="complaint.details && complaint.details.length<40">{{ complaint.details }}</span>
                    <span v-if="complaint.details && complaint.details.length>40">{{ complaint.details.substring(0, 40)+'...'}}</span>
                  </td>
                  <td class="text-center">
                    <span class="badge badge-danger" v-if="parseInt(complaint.status) === 0">Closed</span>
                    <span class="badge badge-warning" v-if="parseInt(complaint.status) === 1">Active</span>
                    <span class="badge badge-success" v-if="parseInt(complaint.status) === 2">Successful</span>
                  </td>
                  <td v-if="moment.utc(complaint.created_at).isSameOrAfter(moment.utc().subtract(7,'days').startOf('day'))">
                    {{ complaint.created_at | fromNow }}
                  </td>
                  <td v-else>
                    {{complaint.created_at | dateFormat}}
                  <td class="project-actions text-center">
<!--                    <button class="btn btn-sm bg-fuchsia" data-toggle="modal" data-target="#viewInfo" @click="setInfo(complaint)">Quick Edit</button>-->
<!--                    /-->
                    <router-link :to="{name: 'complaint-details', params:{id:complaint.id}}" class="btn btn-sm bg-yellow">View</router-link>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-center">
            <pagination class="pagination pagination-sm m-0 float-right" :data="complaints"
                        @pagination-change-page="getComplaints"></pagination>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
    this.getComplaints();
  },
  data() {
    return {
      moment,
      complaints: {
        total:0,
      },
      info: {
        id: null,
        details: null,
        reply: null,
        status: null,
        user_id: null,
        proof_id: null,
        user: {
          name: null,
        },
      },
      filter:{
        status: 3,
        sortBy: 'desc',
      },
      isLoading:false,
      fullPage:false,
      zIndex:10,
    }
  },
  methods: {
    getComplaints(page = 1) {
      this.isLoading = true;
      let status = this.filter.status;
      if (status !== ''){
        status = parseInt(status)
        if (status === 3){
          status = ''
        }
      }
      let sortBy = this.filter.sortBy
      axios.get('admin/complaints?status=' + status + '&sortBy=' + sortBy + '&page=' + page)
          .then(({data}) => {
            this.complaints = data;
            this.isLoading = false;
          })
    },
    setInfo(info) {
      this.info = info
    },
    toggleModal(){
      document.getElementById('close').click();
    },

  },
}
</script>

<style scoped>
.card {
  background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
}
</style>
