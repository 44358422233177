<template>
    <!-- Main content -->
    <section class="content" style="padding-top: 10px">
        <div class="row">
            <div class="col-md-6">
                <div class="card card-dark">
                    <div class="card-header">
                        <h3 class="card-title">Choose Category</h3>

                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                <i class="fas fa-minus"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-body vld-parent">
                      <loading :active.sync="isLoading"
                               :can-cancel="false"
                               :is-full-page="fullPage"
                               :z-index="zIndex"
                               loader="bars">
                      </loading>
                        <div class="form-group">
                            <label for="Category">Category</label>
                          <small class="text-danger" v-if="!sub_categories.available && errors.category_id">{{ errors.category_id[0] }}</small>
                            <select id="Category" class="form-control custom-select" @change="getSubCategories($event)">
                                <option value="" selected disabled> Select Category </option>
                                <option :value="category.id" v-for="category in categories">{{ category.name }}</option>
                            </select>
                        </div>
                        <div class="form-group" v-if="sub_categories.available">
                            <label for="SubCategory">Sub Category</label>
                            <small class="text-danger" v-if="errors.category_id">{{ errors.category_id[0] }}</small>
                            <select id="SubCategory" class="form-control custom-select" v-model="form.category_id"  @change="getCategoryInfo($event)">
                                <option value="" selected disabled> Select SubCategory </option>
                                <option :value="sub_category.id" v-for="sub_category in sub_categories.items">{{ sub_category.name }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <div class="form-group" v-if="parseInt(info.price) > 0">
                                <label>Minimum Unit Price : </label>
                                <span class="float-right"><strong>{{currency}} {{info.price}}</strong></span>
                            </div>
                            <div class="form-group" v-if="parseInt(info.min_amount) > 0">
                                <label>Minimum Order Amount : </label>
                                <span class="float-right"><strong>{{info.min_amount}}</strong></span>
                            </div>
                        </div>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>

            <div class="col-md-6">
                <div class="card card-cyan">
                    <div class="card-header">
                        <h3 class="card-title">Details</h3>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                <i class="fas fa-minus"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <form>
                            <div class="form-group">
                                <label for="Title">Title</label>
                                <small class="text-danger" v-if="errors.title">{{ errors.title[0] }}</small>
                                <input type="text" id="Title" class="form-control" placeholder="Title" v-model="form.title">
                            </div>
                            <div class="form-group">
                                <label for="price">Unit Price</label>
                                <small class="text-danger" v-if="errors.price">{{ errors.price[0] }}</small>
                                <input type="number" id="price" class="form-control" placeholder="Price for each user" v-model.number="form.price" @change="minPrice()">
                            </div>
                            <div class="form-group">
                                <label for="inputAmount">Amount</label>
                                <small class="text-danger" v-if="errors.amount">{{ errors.amount[0] }}</small>
                                <input type="number" id="inputAmount" class="form-control" placeholder="Total Quantity" v-model.number="form.amount" @change="minAmount()">
                            </div>
                            <div class="form-group">
                                <label for="inputTime">Time <small>(in minutes)</small></label>
                                <small class="text-danger" v-if="errors.time">{{ errors.time[0] }}</small>
                                <input type="number" id="inputTime" class="form-control" placeholder="Users need to submit proof after booking task within this time" v-model.number="form.time">
                            </div>
                            <div class="form-group">
                                <label for="URL">Action Url <small>(without http)</small></label>
                                <small class="text-danger" v-if="errors.action_url">{{ errors.action_url[0] }}</small>
                                <input type="text" id="URL" class="form-control" placeholder="Where users need to go to complete task" v-model="form.action_url">
                                <span>Preview URL : <a :href="'//'+form.action_url" target="_blank">{{form.action_url}} </a></span>
                            </div>
                        </form>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <div class="col-md-12">
                <div class="card card-warning">
                    <div class="card-header">
                        <h3 class="card-title text-white">Instructions</h3>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                <i class="fas fa-minus"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                <div class="form-group">
                    <small class="text-danger" v-if="errors.details">{{ errors.details[0] }}</small>
                    <vue-editor id="inputDescription" v-model="form.details" :editorToolbar="customToolbar" />
                </div>
                    </div>
                  <div class="card-footer">
                    <button type="submit" class="btn btn-success float-right" :disabled="loading" @click="createOffer"><svg-loading height="16px" width="16px" class="mr-1" v-if="loading" />Create Job</button>
                  </div>
                </div>
            </div>
        </div>

    </section>
    <!-- /.content -->
</template>

<script>
import axios from "axios";
import svgLoading from "@/views/shared/loading-svg";
import {VueEditor} from "vue2-editor";
export default {
    components: { svgLoading,VueEditor },
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
    created() {
        this.getCategories();
    },
    computed:{
        currency(){ return  this.$currency},
    },
    data(){
        return{
            form:{
                title: null,
                price: null,
                action_url: null,
                details: null,
                category_id: '',
                amount: null,
                time: null,
            },
            errors:{},
            info: {},
            categories: {},
            sub_categories:{
                available: false,
            },
            loading: false,
            customToolbar: [
                [{
                    header: [false, 1, 2, 3]
                }], ["bold", "italic", "underline", "clean"],
                [{align: ""}, {align: "center"}, {align: "right"}, {align: "justify"}], ["blockquote", "video"],
                [{list: "ordered"}, {list: "bullet"}], [{indent: "-1"}, {indent: "+1"}], [{color: []}, {background: []}]
            ],
          isLoading:false,
          fullPage:false,
          zIndex:10,
        }
    },
    methods:{
        getCategories(){
          this.isLoading = true;
            axios.get('categories')
                .then(({data}) => {this.categories = data;this.isLoading = false})
        },
        getSubCategories($event){
          this.isLoading = true;
            this.info = {};
            this.form.category_id = '';
            let id = $event.target.value;
            axios.get('sub-categories/'+id)
                .then(({data}) => {
                    this.sub_categories = data;
                    if (!data.available){
                        axios.get('sub-category-info/'+id)
                            .then(({data}) => {this.info = data;this.form.category_id = parseInt(id)})
                    }
                  this.isLoading = false
                })
        },
        getCategoryInfo($event){
          this.isLoading = true;
            let id = $event.target.value;
            axios.get('sub-category-info/'+id)
                .then(({data}) => {this.info = data;this.isLoading = false})
        },
        createOffer(){
            this.loading = true;
            this.errors = {};
            axios.post('admin/create-offer',this.form)
                .then( () =>{
                    this.$toast('success','Offer Created');
                    this.form = {};
                    this.info = {};
                    this.loading = false;
                })
                .catch( (error) =>{
                    this.loading = false;
                  this.$toast('error','Something Went Wrong');
                    this.errors = error.response.data.errors
                })
        },
        minPrice(){
            this.errors = {};
            let a = parseFloat(this.info.price);
            let b = this.form.price;
            if (a > b) {
                this.errors = {
                    price: ['*Should be equal or greater than minimum unit price']
                };
              this.$toast('error','Error !!');
            }
        },
        minAmount(){
            this.errors = {};
            let a = parseInt(this.info.min_amount);
            let b = parseInt(this.form.amount);
            if (a > b) {
                this.errors = {
                    amount: ['*Should be equal or greater than minimum order amount']
                };
              this.$toast('error','Error !!');
            }
        },
    }

}
</script>

<style scoped>
.row {
    justify-content: center;
}
</style>
