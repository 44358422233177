<template>
  <!-- Main content -->
  <section class="content vld-parent" style="padding-top: 10px">
    <loading :active.sync="isPageLoading"
             :can-cancel="false"
             :is-full-page="fullPage"
             :z-index="zIndex"
             loader="bars">
    </loading>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header bg-yellow">
            <div class="card-title text-white">Complaint Details</div>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group">
              <div class="form-group">
                <a>Status <strong>:</strong> </a>
                <span class="badge badge-danger float-right" v-if="parseInt(complaint.status) === 0">Closed</span>
                <span class="badge badge-warning float-right" v-if="parseInt(complaint.status) === 1">Active</span>
                <span class="badge badge-success float-right" v-if="parseInt(complaint.status) === 2">Successful</span>
              </div>
              <a>Submitted By <strong>:</strong> </a>
              <span class="float-right"
                    v-if="complaint.user.name"><strong>{{complaint.user.name}}</strong></span>
            </div>
            <div class="form-group">
              <a>Against Of <strong>:</strong> </a>
              <span class="float-right"
                    v-if="complaint.against.name"><strong>{{complaint.against.name}}</strong></span>
            </div>
            <div class="form-group">
              <a>Date <strong>:</strong> </a>
              <span class="float-right"
                    v-if="complaint.created_at"><strong>{{complaint.created_at | timeDate}}</strong></span>
            </div>
            <div class="form-group">
              <a>Note <strong>:</strong> </a>
              <p class="font-italic ml-3 mt-2" v-if="complaint.details">{{complaint.details}}</p>
            </div>

          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-header bg-gradient-teal">
            <div class="card-title text-white">Complaint Action</div>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for="status">Change Task Status :</label>
              <div class="text-center">
                <select id="status" class="custom-select" v-model="task_status">
                <option value="" selected disabled>Select Action</option>
                <option value="1">Active</option>
                <option value="2">Completed</option>
                <option value="4">Full Submitted</option>
                <option value="5">On Hold</option>
              </select>
                <button class="btn btn-primary" :disabled="isLoading" @click="updateStatus" style="margin-top: 15px"><svg-loading height="16px" width="16px" class="mr-1" v-if="isLoading" />Update</button>
              </div>
            </div>
            <div v-if="parseInt(complaint.status) === 1">
              <label >Complaint Action <strong>:</strong></label>
              <div class="text-center mt-2">
                <button class="btn btn-danger mr-1" data-toggle="modal" data-target="#reject">
                  Reject Complaint
                </button>
                <button class="btn btn-success ml-1" data-toggle="modal" data-target="#approve">
                  Approve Complaint
                </button>
              </div>
            </div>
            <div class="form-group" v-if="parseInt(complaint.status) === 0">
              <div class="text-center mb-2">  <a class="btn btn-danger">Complaint Was Rejected</a></div>
            </div>
            <div class="form-group" v-if="parseInt(complaint.status) === 2">
              <div class="text-center mb-2"><a class="btn btn-success">Complaint Was Approved</a></div>
            </div>

          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="card card-dark">
          <div class="card-header">
            <h3 class="card-title">Job Details</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group">
              <a>Status <strong>:</strong> </a>
              <span class="badge badge-warning float-right" v-if="parseInt(task.status) === 0">Pending</span>
              <span class="badge badge-primary float-right" v-if="parseInt(task.status) === 1">Active</span>
              <span class="badge badge-success float-right" v-if="parseInt(task.status) === 2">Completed</span>
              <span class="badge badge-danger float-right" v-if="parseInt(task.status) === 3">Rejected</span>
              <span class="badge badge-secondary float-right" v-if="parseInt(task.status) === 4">Full Submitted</span>
              <span class="badge badge-dark float-right" v-if="parseInt(task.status) === 5">On Hold</span>
            </div>
            <div class="form-group">
              <a>Unit Price <strong>:</strong> </a>
              <span class="float-right"
                    v-if="task.price"><strong>${{task.price}}</strong></span>
            </div>
            <div class="form-group">
              <a>Job Instructions <strong>:</strong> </a>
              <div class="card text-center mt-2">
                <div class="card-header bg-secondary">
                  <div class="card-title text-white" v-if="task.title">{{task.title}}</div>
                </div>
                <div v-if="task.details" class="card-body bg-gray-light" v-html="task.details">
              </div>

              </div>
            </div>

          </div>
          <!-- /.card-body -->
        </div>
        <!-- /.card -->
      </div>

      <div class="col-md-6">
        <div class="card card-cyan">
          <div class="card-header">
            <h3 class="card-title">Proof Details</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group">
              <a>Status <strong>:</strong> </a>
              <a class="badge badge-warning float-right" v-if="parseInt(proof.status) === 0">Pending</a>
              <a class="badge badge-success float-right" v-if="parseInt(proof.status) === 1">Approved</a>
              <a class="badge badge-danger float-right" v-if="parseInt(proof.status) === 2">Rejected</a>
              <a class="badge badge-primary float-right" v-if="parseInt(proof.status) === 3">In Review</a>
              <a class="badge badge-primary float-right" v-if="parseInt(proof.status) === 4">In Review</a>
            </div>
            <div class="form-group">
              <a>Completed at <strong>:</strong> </a>
              <span class="float-right"
                    v-if="proof.created_at"><strong>{{proof.created_at | timeDate}}</strong></span>
            </div>
            <div class="form-group">
              <a>Note <strong>:</strong> </a>
              <p class="font-italic ml-3 mt-2" v-if="proof.comment">{{proof.comment}}</p>
            </div>
            <div class="form-group">
              <a>Screenshots <strong>:</strong> </a>
              <div class="card text-center mt-2">
                <div id="proofImage" v-if="proof.images.length > 1">
                  <expandable-image v-for="(image,index) in proof.images" :key="index" :src="url+image"
                                    :close-on-background-click="true" alt="" style="padding: 5px"/>
                </div>
                <div v-else class="w-50 px-2 py-2 justify-content-center">
                  <expandable-image v-for="(image,index) in proof.images" :key="index" :src="url+image"
                                    :close-on-background-click="true" alt="" style="padding: 5px"/>
                </div>
              </div>
            </div>
            <div class="form-group">
              <a>Reject Note <strong>:</strong> </a>
              <p class="font-italic text-red ml-3 mt-2" v-if="proof.reject_note">{{proof.reject_note}}</p>
              <p class="font-italic text-red ml-3 mt-2" v-else>No Reject Note Provided</p>
            </div>
          </div>
          <!-- /.card-body -->
        </div>
        <!-- /.card -->
      </div>

    </div>

    <div class="modal fade" id="reject" tabindex="-1" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Reject Complaint</h5>
                <button type="button" class="btn btn-dark" data-dismiss="modal" aria-label="Close" id="close-reject">
                  <i class="fas fa-times-circle"></i>
                </button>
              </div>
              <div class="modal-body">
                <div class="text-center bg-warning">
                  <h2 class="text-white">Important Note !!</h2>
                </div>
                <div class="text-center bg-yellow">
                  <p>If you reject this complaint then the proof status would be changed to rejected and user won't get any price. You can send this user a warning message or block user for false complaint. Choose a action and write a reply to the user.</p>
                </div>
                <small class="text-danger" v-if="errors.action">{{ errors.action[0] }}</small>
                <div class="form-group">
                  <label for="reject-action">Block User <small>({{ complaint.user.name }})</small> :</label>
                    <select id="reject-action" class="custom-select" v-model="info.action">
                      <option value="" selected disabled>Select Action</option>
                      <option value="1">Yes, Block</option>
                      <option value="2">No, Send Warning</option>
                    </select>
                </div>
                <small class="text-danger" v-if="errors.message">{{ errors.message[0] }}</small>
                <div class="form-group" v-if="info.action == 2">
                  <label for="reject-message">Warning Message :</label>
                  <textarea type="text" id="reject-message" class="form-control" v-model="info.message"></textarea>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-danger" :disabled="isLoading" @click="rejectComplaint"><svg-loading height="16px" width="16px" class="mr-1" v-if="isLoading"/>Reject</button>
              </div>
            </div>
          </div>
        </div>

    <div class="modal fade" id="approve" tabindex="-1" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Approve Complaint</h5>
                <button type="button" class="btn btn-dark" data-dismiss="modal" aria-label="Close" id="close-approve">
                  <i class="fas fa-times-circle"></i>
                </button>
              </div>
              <div class="modal-body">
                <div class="text-center bg-warning">
                  <h2 class="text-white">Important Note !!</h2>
                </div>
                <div class="text-center bg-yellow">
                  <p>If you approve this complaint then the proof status would be changed to approved and user will get price from owner's wallet. You can send this task owner a warning message or block owner for cheating. Choose a action and write a reply to the user.</p>
                </div>
                <small class="text-danger" v-if="errors.action">{{ errors.action[0] }}</small>
                <div class="form-group">
                  <label for="approve-action">Block User <small>({{ complaint.against.name }})</small> :</label>
                  <select id="approve-action" class="custom-select" v-model="info.action">
                    <option value="" selected disabled>Select Action</option>
                    <option value="1">Yes, Block</option>
                    <option value="2">No, Send Warning</option>
                  </select>
                </div>
                <small class="text-danger" v-if="errors.message">{{ errors.message[0] }}</small>
                <div class="form-group" v-if="info.action == 2">
                  <label for="approve-message">Warning Message :</label>
                  <textarea type="text" id="approve-message" class="form-control" v-model="info.message"></textarea>
                </div>
                <small class="text-danger" v-if="errors.reply">{{ errors.reply[0] }}</small>
                <div class="form-group">
                  <label for="approve-reply">Reply To User :</label>
                  <textarea type="text" id="approve-reply" class="form-control" v-model="info.reply"></textarea>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-success" :disabled="isLoading" @click="approveComplaint"><svg-loading height="16px" width="16px" class="mr-1" v-if="isLoading"/>Approve</button>
              </div>
            </div>
          </div>
        </div>

  </section>
  <!-- /.content -->
</template>

<script>
import axios from "axios";
import ExpandableImage from "@/views/shared/ExpandableImage";
import svgLoading from "@/views/shared/loading-svg";
export default {
  components: {svgLoading,ExpandableImage},
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
    this.getInfo();
  },
  data(){
    return{
      url: this.$siteurl,
      complaint:{
        id: null,
        details: null,
        is_user: null,
        reply: null,
        status: null,
        user_id: null,
        user2_id: null,
        proof_id: null,
        user: {
          id: null,
          name: null,
          username: null,
        },
        against: {
          id: null,
          name: null,
          username: null,
        },
      },
       proof:{
          comment: null,
          images:[],
          reject_note: null,
          status: null,
         created_at: null,
       },
       task:{
            id: null,
            title: null,
            price: null,
            action_url: null,
            details: null,
            status: null,
       },
      task_status: '',
      info:{
        reply: null,
        action: '',
        message:null,
      },
      errors:{},
      isLoading: false,
      isPageLoading:false,
      fullPage:false,
      zIndex:10,

    }
  },
  methods:{
    getInfo(){
      this.isPageLoading = true;
      let id = this.$route.params.id
      axios.get('admin/complaint-info?id='+id)
          .then(({data}) => {
            this.complaint = data.complaint
            this.task = data.task
            this.proof = data.proof
            this.proof.images = JSON.parse(this.proof.images)
            this.isPageLoading = false;
          })
          .catch((error) => {
            this.isPageLoading = false;
        if (error.response.status === 404 || 401) {
          this.$router.replace({name: 'notFound'})
        }
      })
    },
    updateStatus(){
      this.isLoading = true;
      axios.post('admin/offer-status-update',{id:this.task.id,status:this.task_status})
          .then(({data})=> {
            this.getInfo();
            this.isLoading = false;
            this.$toast('success', ''+data.message+'')
          })
          .catch((error)=>{
            this.isLoading = false;
            this.errors = error.response.data.errors
            this.$toast('error', 'Something Went Wrong')
          })
    },
    approveComplaint(){
      this.isLoading = true;
      axios.post('admin/approve-complaint',{id:this.complaint.id,reply:this.info.reply,action:this.info.action,task:this.task.id,message:this.info.message})
          .then(({data})=> {
            this.getInfo();
            this.isLoading = false;
            document.getElementById('close-approve').click();
            this.$toast('success', ''+data.message+'')
          })
          .catch((error)=>{
            this.isLoading = true;
            this.errors = error.response.data.errors
            this.$toast('error', 'Something Went Wrong')
          })
    },
    rejectComplaint(){
      this.isLoading = true;
      axios.post('admin/reject-complaint',{id:this.complaint.id,action:this.info.action,message:this.info.message})
          .then(({data})=> {
            this.getInfo();
            this.isLoading = false;
            document.getElementById('close-reject').click();
            this.$toast('success', ''+data.message+'')
          })
          .catch((error)=>{
            this.isLoading = false;
            this.errors = error.response.data.errors
            this.$toast('error', 'Something Went Wrong')
          })
    },

  }

}
</script>

<style scoped>
.row {
  justify-content: center;
}
#proofImage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  max-width: 100%;
  position: relative;
}
</style>
