<template>
  <!-- Main content -->
  <section class="content" style="padding-top: 10px">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-navy">
          <div class="card-header">
            <h3 class="card-title">User Messages</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage"
                     :z-index="zIndex"
                     loader="bars">
            </loading>
            <!-- Chat -->
            <div class="direct-chat-messages" id="chat">

              <div v-if="parseInt(messages.count) !== 0" class="direct-chat-msg right" v-for="message in messages.items" :key="message.id">
                <div class="direct-chat-infos clearfix">
                  <span class="direct-chat-timestamp ml-1"><small>{{ message.created_at | timeDate }}</small></span>
                </div>
                <div class="direct-chat-text mr-1">
                  <div v-html="message.message"></div>
                </div>
              </div>
              <div v-if="parseInt(messages.count) === 0" class="direct-chat-msg right">
                <div class="direct-chat-infos clearfix">
                  <span class="direct-chat-timestamp ml-1"><small>N/A</small></span>
                </div>
                <div class="direct-chat-text mr-1">
                  No Previous Messages Available !!
                </div>
              </div>


            </div>
            <!--/.direct-chat-messages-->

          </div>
          <div class="card-footer">
            <div class="input-group">
              <textarea type="text" name="message" placeholder="Type Message ..." class="form-control" v-model="messages.message_text" />
              <span class="input-group-append ml-1">
                      <button class="btn btn-warning rounded" @click.prevent="sendMessage">Send</button>
                    </span>
            </div>
          </div>
        </div>
        <!-- /.card -->
      </div>

    </div>

  </section>
  <!-- /.content -->
</template>

<script>
import axios from "axios";
export default {
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
    this.getMessages();
  },
  data(){
    return{
      url: this.$siteurl,
      messages: {
        message_text: null,
        count:0,
        items:{}
      },
      errors:{},
      isLoading:false,
      fullPage:false,
      zIndex:10,
    }
  },
  methods:{
    getMessages(){
      this.isLoading = true;
      let username = this.$route.params.username
      axios.get('admin/user-messages',{params:{username:username}})
          .then(({data}) => {this.messages.items = data.messages;this.messages.count = data.total;this.isLoading = false;this.chatScroll()})
          .catch(error =>{
            this.errors = error;
            this.isLoading = false;
          })
    },
    sendMessage(){
      let username = this.$route.params.username
      axios.post('admin/send-message',{username:username,message:this.messages.message_text})
          .then(({data})=> {
            this.messages.message_text = null;
            this.$toast('success', ''+data.message+'')
            this.getMessages();
          })
    },
    chatScroll(){
      let msg = $('#chat');
      msg.animate({ scrollTop: msg.prop('scrollHeight')}, 1000);
    }
  }

}
</script>

<style scoped>
.row {
  justify-content: center;
}
.direct-chat-messages {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  padding: 10px;
  height: 50vh;
  max-height: 80vh;
  overflow: auto;
}
#profilePic {
  background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
}
#profileBox{
  background-image: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
}
</style>
