<template>
  <!-- Main content -->
  <section class="content" style="padding-top: 10px">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-navy">
          <div class="card-header">
            <h3 class="card-title">App Info</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage"
                     :z-index="zIndex"
                     loader="bars">
            </loading>
            <!-- Form Row-->
              <div class="row justify-content-start">
              <div class="col-md-6">
              <small class="text-danger" v-if="errors.name">{{ errors.name[0] }}</small>
                <label>App Name :</label>
                <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="App Name" v-model="info.name">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-user"></span>
                  </div>
                </div>
              </div>
              </div>
              <div class="col-md-6">
              <small class="text-danger" v-if="errors.logo">{{ errors.logo[0] }}</small>
                <label>App Logo :</label>
                <div class="input-group mb-3">
                  <div class="card-body box-profile profile-card">
                    <div class="text-center">
                      <img v-if="info.logo" class="profile-user-img img-fluid" :src="url+info.logo" :alt="info.name">
                      <img v-else class="profile-user-img img-fluid bg-white" :src="url+'user.png'" :alt="info.name">
                    </div>
                    <i class="fas fa-pen text-white float-right" style="cursor: pointer" data-toggle="modal" data-target="#logoChange"></i>
                  </div>
              </div>
              </div>
              <div class="col-md-6">
              <small class="text-danger" v-if="errors.logotext">{{ errors.logotext[0] }}</small>
                <label>Branding Text :</label>
                <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="App Branding" v-model="info.logotext">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-user"></span>
                  </div>
                </div>
              </div>
              </div>
              <div class="col-md-6">
                <label>App URL :</label>
              <small class="text-danger" v-if="errors.url">{{ errors.url[0] }}</small>
              <div class="input-group mb-3">
                <input type="url" class="form-control" placeholder="App URL" v-model="info.url">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-globe"></span>
                  </div>
                </div>
              </div>
              </div>

                <div class="col-md-6">
                <label>Address :</label>
              <small class="text-danger" v-if="errors.address">{{ errors.address[0] }}</small>
              <div class="input-group mb-3">
                <input type="url" class="form-control" placeholder="Address" v-model="info.address">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-address-card"></span>
                  </div>
                </div>
              </div>
              </div>

                <div class="col-md-6">
                <label>Contact Email :</label>
              <small class="text-danger" v-if="errors.contact_email">{{ errors.contact_email[0] }}</small>
              <div class="input-group mb-3">
                <input type="url" class="form-control" placeholder="Contact Email" v-model="info.contact_email">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              </div>

            <!-- Form Row End -->
                 </div>
            <!-- Form Row End -->
            <hr>
            <div class="text-right">
              <button class="col-4 col-md-3 col-lg-2 btn btn-success" @click.prevent="updateInfo">Update</button>
            </div>

          </div>
          <!-- /.card-body -->

        </div>
        <!-- /.card -->
      </div>

    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card card-navy">
          <div class="card-header">
            <h3 class="card-title">App Settings</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage"
                     :z-index="zIndex"
                     loader="bars">
            </loading>
            <!-- Form Row-->
              <div class="row justify-content-start">
                <div class="col-md-6">
                  <small class="text-danger" v-if="errors.need_verification">{{ errors.need_verification[0] }}</small>
                  <div class="form-group">
                    <label>Email Verification <strong>:</strong> </label>
                    <switches class="float-right rounded-pill pr-3" v-model="settings.need_verification" />
                  </div>
                </div>
                <div class="col-md-6">
                  <small class="text-danger" v-if="errors.sass">{{ errors.saas[0] }}</small>
                  <div class="form-group">
                    <label>Saas Feature <strong>:</strong> </label>
                    <switches class="float-right rounded-pill pr-3" v-model="settings.saas" />
                  </div>
                </div>
                <div class="col-md-6">
                  <small class="text-danger" v-if="errors.manual_payment">{{ errors.manual_payment[0] }}</small>
                  <div class="form-group">
                    <label>Manual Payment <strong>:</strong> </label>
                    <switches class="float-right rounded-pill pr-3" v-model="settings.manual_payment" />
                  </div>
                </div>
                <div class="col-md-6">

                </div>

              <div class="col-md-6">
              <small class="text-danger" v-if="errors.currency">{{ errors.currency[0] }}</small>
                <label>Currency :</label>
                <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="App Currency Symbol" v-model="settings.currency">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-dollar-sign"></span>
                  </div>
                </div>
              </div>
              </div>

              <div class="col-md-6">
              <small class="text-danger" v-if="errors.booking_limit">{{ errors.booking_limit[0] }}</small>
                <label>Task Booking Limit :</label>
                <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Task Booking Limit" v-model="settings.booking_limit">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-tasks"></span>
                  </div>
                </div>
              </div>
              </div>
              <div class="col-md-6">
                <label>Screenshot Limit :</label>
              <small class="text-danger" v-if="errors.ss_limit">{{ errors.ss_limit[0] }}</small>
              <div class="input-group mb-3">
                <input type="url" class="form-control" placeholder="Screenshot Limit" v-model="settings.ss_limit">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-mobile"></span>
                  </div>
                </div>
              </div>
              </div>
              <div class="col-md-6">
                <label>Withdrawal Commission <small>(%)</small> :</label>
              <small class="text-danger" v-if="errors.withdraw_com">{{ errors.withdraw_com[0] }}</small>
              <div class="input-group mb-3">
                <input type="number" class="form-control" placeholder="Withdraw Commission" v-model="settings.withdraw_com">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-dollar-sign"></span>
                  </div>
                </div>
              </div>
              </div>
                <div class="col-md-6">
                  <label>Offer Commission <small>(%)</small> :</label>
                  <small class="text-danger" v-if="errors.task_com">{{ errors.task_com[0] }}</small>
                  <div class="input-group mb-3">
                    <input type="number" class="form-control" placeholder="Offer Commission" v-model.number="settings.task_com">
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-dollar-sign"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label>Referrer Withdrawal Reward :</label>
                  <small class="text-danger" v-if="errors.ref_wr">{{ errors.ref_wr[0] }}</small>
                  <div class="input-group mb-3">
                    <input type="number" class="form-control" placeholder="Deposit Commission" v-model.number="settings.ref_wr">
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-dollar-sign"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label>Referrer Offer Create Reward :</label>
                  <small class="text-danger" v-if="errors.ref_or">{{ errors.ref_or[0] }}</small>
                  <div class="input-group mb-3">
                    <input type="number" class="form-control" placeholder="Referrer Commission" v-model.number="settings.ref_or">
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-dollar-sign"></span>
                      </div>
                    </div>
                  </div>
                </div>
            <!-- Form Row End -->
                 </div>
            <!-- Form Row End -->
            <hr>
            <div class="text-right">
              <button class="col-4 col-md-3 col-lg-2 btn btn-success" @click.prevent="updateSettings">Update</button>
            </div>

          </div>
          <!-- /.card-body -->

        </div>
        <!-- /.card -->
      </div>

    </div>

    <!-- Modal -->
    <div class="modal fade" id="logoChange" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Update Logo</h5>
            <button type="button" class="btn btn-dark" data-dismiss="modal" aria-label="Close" id="close"><i class="fas fa-times-circle"></i></button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Logo</label>
              <div class="col-sm-10">
                <div class="card card-primary justify-content-center profile-card">
                  <div class="card-body box-profile">
                    <div class="text-center">
                      <input type="file" accept=".jpg,.png,.jpeg,.heic" class="custom-file" @change="imageChange">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row"  v-if="newLogo">
              <label class="col-sm-2 col-form-label">Preview</label>
              <div class="col-sm-10">
                <div class="card card-primary justify-content-center profile-card">
                  <div class="card-body box-profile">
                    <div class="text-center">
                      <img class="profile-user-img img-fluid"
                           :src="newLogo"
                           alt="User profile picture">
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-success" :disabled="!newLogo" @click.prevent="updateLogo">Update</button>
          </div>
        </div>
      </div>
    </div>

  </section>
  <!-- /.content -->
</template>

<script>
import axios from "axios";
import switches from "@/views/shared/switches";
export default {
  components: {switches},
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
    this.getInfo();
  },
  data(){
    return{
      url: this.$siteurl,
      info:{
        name: null,
        url: null,
        logo: null,
        logotext: null,
        address:null,
        contact_email: null,
      },
      settings: {
        saas: true,
        manual_payment: true,
        need_verification: false,
        map: false,
        currency:null,
        booking_limit:null,
        ss_limit:null,
        withdraw_com: null,
        task_com: null,
        ref_wr: null,
        ref_or: null,

      },
      newLogo: null,
      errors:{},
      isLoading:false,
      fullPage:false,
      zIndex:10,
    }
  },
  methods:{
    getInfo(){
      this.isLoading = true;
      axios.get('admin/settings')
          .then(({data}) => {
            this.settings = data.settings;
            this.info = data.info;
            this.isLoading = false
          })
    },
    updateSettings(){
      axios.patch('admin/settings',this.settings)
          .then(({data})=> {
            this.$toast('success', ''+data.message+'')
          })
    },
    updateInfo(){
      axios.patch('admin/app-info',this.info)
          .then(({data})=> {
            this.$toast('success', ''+data.message+'')
          })
    },
    updateLogo(){
      axios.post('admin/update-logo',{logo:this.newLogo})
          .then(({data})=> {
            this.$toast('success', ''+data.message+'')
            document.getElementById('close').click();
          })
          .catch(error=>{
            this.$toast('error', 'Something Went Wrong')
          })
    },
    imageChange(event) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.onload = event =>{
        this.newLogo = event.target.result
      };
      reader.readAsDataURL(file);

    },

  }

}
</script>

<style scoped>
.row {
  justify-content: center;
}
.profile-card{
  background-image: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
}
</style>
